//---------------------------------------------------------//
// Tables
//---------------------------------------------------------// 



// Inline Table

.inline-table, .block-table {
    display: table;
    margin-bottom: $globalMarginBottom;
    &:last-child {
        margin-bottom: 0;
    }
    .td {
        display: table-cell;
        vertical-align: middle;
        padding-right: 10px;
        &.v-top {
            vertical-align: top;
        }
        &:last-child {
            padding-right: 0;
        }
    }
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p {
        &:last-child {
            margin-bottom: 0;
        }
    }
    @include media-breakpoint-down(sm) {
        &.table-block-mobile {
            text-align: center;
            display: block;
            .td {
                display: block;
                padding-right: 0;
            }
        }
    }
    @include media-breakpoint-up(sm) {
        .td {
            padding-right: 15px;
        }
    }
    @include media-breakpoint-up(md) {
        .td {
            padding-right: 20px;
        }
    }
}


// Block Table

.block-table {
    display: table;
    width: 100%;
    .td {
        &:last-child {
            > * {
                margin-left: auto;
            }
            text-align: right;
        }
    }
}