//---------------------------------------------------------//
// Images
//---------------------------------------------------------// 

.image-wrapper, .content-image-wrapper {
	position: relative;
	width: 100%;
	margin: 0 auto;
	img {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
}

.content-image-inner {
	position: absolute;
	top: 0;
	width: 100%;
}

.placeholder-svg {
	border: none;
	position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}



// Ratio

.ratio-wrapper {
	position: relative;
	&:before {
		content: '';
		display: block;
		padding-top: 100%;
	}
	> * {
		@include background-image();
	}
	.video-player {
		position: absolute;
	}
}

.ratio4-3 {
	&:before {
		padding-top: calc(3 / 4 * 100%);
	}
}
.ratio16-9 {
	&:before {
		padding-top: calc(9 / 16 * 100%);
	}
}
.ratio16-10 {
	&:before {
		padding-top: calc(10 / 16 * 100%);
	}
}