//---------------------------------------------------------//
// Icons
//---------------------------------------------------------//
// - All Icons
//---------------------------------------------------------//

.icon-image,
.icon {
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Icon Sizes

.icon-sm {
    font-size: rem(14);
}
.icon-md {
    font-size: rem(16);
}
.icon-lg {
    font-size: rem(18);
}

// Icon Margin

.icon-margin-right {
    margin-right: rem(12);
}
.icon-margin-left {
    margin-left: rem(12);
}

// Icon SVG

.icon-svg {
    path {
        fill: currentColor;
    }
}

.icon-hover {
    .icon-svg {
        path {
            fill: $body-color;
        }
    }
    &:hover,
    &:focus {
        .icon-svg {
            path {
                fill: $primary;
            }
        }
    }
}

.icon-image-phone {
    background: url("#{$icon-url}/icon-phone.png");
    @include background-retina(22, 30);
}
