//---------------------------------------------------------//
// Header
//---------------------------------------------------------//

.navbar {
    background-color: $primary;
    color: #ffffff;
    > .container {
        display: flex;
        height: 100px;
        align-items: center;
    }
    h1 {
        margin-bottom: 0;
    }
    .navbar-left,
    .navbar-right {
    }
    .navbar-right {
        margin-left: auto;
        text-align: right;
    }
    .navbar-title {
        font-size: 18px;
        font-weight: bold;
    }
    .navbar-col,
    .navbar-col-divider {
        display: inline-block;
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
    }
    .navbar-col-divider {
        height: 14px;
        width: 1px;
        background-color: rgba($white, 0.3);
    }
    .navbar-logo {
        font-size: 12px;
        text-transform: uppercase;
    }
    @include media-breakpoint-down(sm) {
        .navbar-brand {
            img {
                height: 20px !important;
            }
        }
    }
}
