//---------------------------------------------------------//
// MIXINS
//---------------------------------------------------------//
// - Custom mixins
//---------------------------------------------------------//

// PX TO REM conversion
//---------------------------------------------------------//

@function parseInt($num) {
    @return $num / ($num * 0 + 1);
}

@function rem($values) {
    $length: length($values);
    $list: ();

    @for $i from 1 through $length {
        $item: nth($values, $i);
        $list: append($list, remCalculate($item));
    }

    @return $list;
}

@function remCalculate($values) {
    @if type-of($values) == "number" {
        $remValues: parseInt($values) / parseInt($browser-context) * 1rem;

        @if $remValues == 0rem {
            $remValues: 0;
        }

        @return $remValues;
    } @else if $values == "auto" {
        $values: auto;

        @return $values;
    } @else {
        @warn 'There is no unit conversion for that specific values';
    }
}

// Box Shadow
//---------------------------------------------------------//

@mixin box-shadow() {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

// Background Size (Retina)
//---------------------------------------------------------//

@mixin background-retina($width, $height) {
    background-size: rem($width $height);
    width: rem($width);
    height: rem($height);
}

// Position Center - Horizontal/Vertical
//---------------------------------------------------------//

@mixin position-vertical() {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

@mixin position-horizontal() {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

// Color Inherit
//---------------------------------------------------------//

@mixin color-inherit() {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    ul,
    ol,
    a:not(.btn) {
        color: inherit;
    }
}

// No margin last child
//---------------------------------------------------------//

@mixin no-margin-last() {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    ul,
    ol {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

// Background Image
//---------------------------------------------------------//

@mixin background-image() {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
