//---------------------------------------------------------//
// Main
//---------------------------------------------------------//

// Sections - padding content

.section {
    .section-content {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
    .section-header {
        margin-bottom: 50px;
    }
    @include media-breakpoint-up(sm) {
        .section-content {
            padding-top: 70px;
            padding-bottom: 70px;
        }
        .section-header {
            margin-bottom: 50px;
        }
    }
}

.line {
    width: 30px;
    height: 1px;
    display: inline-block;
    margin-bottom: 30px;
    background-color: rgba($primary, 0.4);
}
