//---------------------------------------------------------//
// HELPERS
//---------------------------------------------------------// 
// - Custom 
//---------------------------------------------------------//


// Hide only visually, but have it available for screen readers:

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// Extends the .visuallyhidden class to allow the element
// to be focusable when navigated to via the keyboard:


.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}



// Browserupgrade

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}


