.hero {
    background-color: #ffffff;
    position: relative;
    min-height: 500px;
    padding: 70px 0;
    display: flex;
    align-items: center;
    .hero-image,
    .hero-image-inner {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
    }
    .hero-image {
        width: 50%;
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .hero-content {
        position: relative;
        z-index: 2;
    }
    @include media-breakpoint-down(sm) {
        color: #ffffff;
        .hero-image {
            width: 100%;
            background-position: 50% 50%;
            opacity: 0.75;
        }
        .text-muted {
            color: #ffffff !important;
        }
    }
}
