//---------------------------------------------------------//
// Footer
//---------------------------------------------------------//

.footer-logo {
    font-size: 12px;
    text-transform: uppercase;
    @include media-breakpoint-down(sm) {
        img {
            height: 20px;
        }
    }
}
