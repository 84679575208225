//---------------------------------------------------------//
// TYPOGRAPHY
//---------------------------------------------------------//
// - All types of typography
// - Font-face
// - Headings
// - Paragraphs
//---------------------------------------------------------//

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-top: 0;
    margin-bottom: $globalMarginBottom;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    a {
        color: inherit;
        &:hover,
        &:focus {
            color: $link-hover-color;
            text-decoration: none;
        }
    }
}

.text-link {
    color: $gray;
    &:hover,
    &:focus {
        color: $gray-darker;
    }
}

// Font Weight

.font-weight-medium {
    font-weight: 500 !important;
}
.font-weight-semi {
    font-weight: 600 !important;
}

// Line Heights

.line-height-sm {
    line-height: $line-height-sm;
}
.line-height-xs {
    line-height: $line-height-xs;
}
.line-height-lg {
    line-height: $line-height-lg;
}

// Letter Spacing

.letter-spacing-1 {
    letter-spacing: 1px;
}
.letter-spacing-2 {
    letter-spacing: 2px;
}
.letter-spacing-3 {
    letter-spacing: 2px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    &.h1,
    &.h2,
    &.h3,
    &.h4,
    &.h5,
    &.h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        line-height: $headings-line-height;
    }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    line-height: $line-height-base;
}

strong {
    &.h1,
    &.h2,
    &.h3,
    &.h4,
    &.h5,
    &.h6 {
        font-weight: bold;
    }
}

// Heading

h1,
.h1 {
    font-size: 30px;
    @include media-breakpoint-up(md) {
        font-size: 36px;
    }
}

h2,
.h2 {
    font-size: 30px;
}

h3,
.h3 {
    font-size: 24px;
}

h4,
.h4 {
    font-size: 20px;
}

h5,
.h5 {
    font-size: 18px;
}

h6,
.h6 {
    font-size: 16px;
}

// Text Sizes

.text-sm {
    font-size: 14px;
}
.text-tiny {
    font-size: 12px;
}
