//---------------------------------------------------------//
// Buttons
//---------------------------------------------------------//
// - All Buttons
//---------------------------------------------------------//

button,
a,
.btn {
    @include transition(
        background-color $easing-speed $easing,
        border-color $easing-speed $easing,
        color $easing-speed $easing
    );
}

button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
}

.btn {
    &.btn-white {
        background-color: $white;
        color: $primary;
        border-color: $white;
        &:hover,
        &:focus {
            background-color: darken($white, 5%);
            border-color: darken($white, 5%);
        }
    }
}
