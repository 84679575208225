//---------------------------------------------------------//
// Override Boostrap Variables
//---------------------------------------------------------//

// Colors

$gray-white: #f5f5f5;
$gray-light: #cccccc;
$gray-lighter: #eeeeee;
$gray: #8a8888;
$gray-dark: #47494b;
$gray-darker: #323232;

$black: #000000;
$white: #ffffff;
$alert: #ffc11a;

$primary: #006aff;
$secondary: #f2f7ff;

$theme-colors: () !default;
$theme-colors: map-merge(
    (
        "default": $black,
    ),
    $theme-colors
);

// Grid - Breakpoints - Container

$container-xl: 1200px;

$grid-gutter-width: 40px;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: $container-xl + $grid-gutter-width,
);

$spacer: 1rem !default;
$spacer-1: 0.3125rem; // 5px
$spacer-2: 0.625rem; // 10px
$spacer-3: 0.9375rem; // 15px
$spacer-4: 1.25rem; // 20px
$spacer-5: 1.563rem; // 25px
$spacer-6: 1.875rem; // 30px
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
    (
        0: 0,
        1: $spacer-1,
        2: $spacer-2,
        3: $spacer-3,
        4: $spacer-4,
        5: $spacer-5,
        6: $spacer-6,
    ),
    $spacers
);

// Global

$body-bg: #ffffff;
$body-color: #000000;

$text-muted: #adadad;

$border-color: rgba($gray-light, 0.4);

$link-color: $primary;
$link-hover-color: darken($primary, 10%);
$link-hover-decoration: underline;

// Typography

$font-size-base: 1rem;
$font-size-xl: 1rem;
$line-height-base: 1.5;
$line-height-sm: 1.3;
$line-height-xs: 1.1;

$font-family-base: "Poppins", sans-serif;
$font-family-secondary: "bebas_neue", sans-serif;

$headings-font-weight: normal;
$headings-font-family: $font-family-base;
$headings-line-height: 1.2;

$hr-border-color: $gray-lighter;
$hr-margin-y: 1.5rem;

$lead-font-size: 18px;
$lead-font-weight: normal;

$display4-size: 3rem;

$display1-weight: inherit;
$display2-weight: inherit;
$display3-weight: inherit;
$display4-weight: inherit;

$display-line-height: 1.1;

// Components - Padding

$border-radius: 0.1875rem;
$border-radius-lg: 0.1875rem;
$border-radius-sm: 0.1875rem;

$input-btn-font-size: 12px;
$input-btn-font-size-sm: $input-btn-font-size;

$input-btn-padding-y: 0.8125rem;
$input-btn-padding-x: 1.5rem;

$input-btn-padding-y-sm: 0.6875rem;
$input-btn-padding-x-sm: 1rem;

$input-btn-padding-y-lg: 1.125rem;
$input-btn-padding-x-lg: 1.5rem;

$input-btn-padding-y-xl: 1.375rem;
$input-btn-padding-x-xl: 1.25rem;

$label-margin-bottom: 0.58rem;

// Buttons

$btn-font-weight: 600;
$btn-font-size-lg: 16px;

$btn-padding-x-lg: 1.875rem;

// Forms

$form-group-margin-bottom: 1.438rem;

$input-color: $body-color;
$input-border-color: rgba($primary, 0.18);
$input-placeholder-color: $text-muted;
$input-bg: $white;

// Cards

$card-spacer-y: 40px;
$card-spacer-x: 40px;
$card-color: $body-color;
$card-bg: $white;
$card-border-color: $white;
