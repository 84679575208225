//---------------------------------------------------------//
// List
//---------------------------------------------------------//

.list {
    list-style: none;
    padding: 0;
    margin: 0 0 $globalMarginBottom 0;
    li {
        display: block;
        margin-bottom: rem(10);
        &:last-child {
            margin-bottom: 0;
        }
    }
}

// Inline List

.list-inline {
    list-style: none;
    padding-left: 0;
    margin-bottom: -10px;
    margin-left: -10px;
    margin-right: -10px;
    li {
        position: relative;
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
    &.list-divider {
        li {
            padding-right: 24px;
            vertical-align: middle;
            &:after {
                content: "";
                height: 14px;
                width: 1px;
                background-color: rgba($primary, 0.4);
                position: absolute;
                top: 50%;
                margin-top: -7px;
                right: 0;
            }
            &:last-child {
                padding-right: 0;
                &:after {
                    display: none;
                }
            }
        }
    }
    &.list-divider-white {
        li {
            &:after {
                background-color: rgba($white, 0.4);
            }
        }
    }
    @include media-breakpoint-down(sm) {
        margin-left: -7px;
        margin-right: -7px;
        li {
            margin-left: 7px;
            margin-right: 7px;
        }
        &.list-divider {
            li {
                padding-right: 18px;
            }
        }
    }
}

// Bullets

.bullets {
    list-style: none;
    padding-left: 0;
    li {
        position: relative;
        margin-bottom: rem(12);
        display: flex;
        &:before {
            content: "\2022";
            color: $primary;
            flex-shrink: 0;
            padding-right: rem(11);
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

// Inline Bullets

.inline-bullets {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-flow: row wrap;
    li {
        position: relative;
        margin-bottom: rem(12);
        display: flex;
        &:after {
            content: "\2022";
            flex-shrink: 0;
            padding-left: rem(11);
            margin-right: rem(11);
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}
