//---------------------------------------------------------//
// Base
//---------------------------------------------------------//

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.bg-primary {
    color: $white;
}

// Grid Column

.grid-column {
    margin-bottom: -$grid-gutter-width;
    .thumbnail,
    .card {
        margin-bottom: 0;
    }
    > .col,
    > [class*="col-"] {
        margin-bottom: $grid-gutter-width;
    }
}

// Gutters

.gutter-sm {
    margin-left: -10px;
    margin-right: -10px;
    > .col,
    > [class*="col-"] {
        padding-left: 10px;
        padding-right: 10px;
    }
}
