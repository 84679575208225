//---------------------------------------------------------//
// Forms
//---------------------------------------------------------//

select {
    background-image: url(#{'"{{ "icon-select-arrow.png" | asset_url }}"'}) !important;
    background-size: 10px 7px !important;
    background-position: right $input-btn-padding-x center !important;
    background-repeat: no-repeat !important;
    padding-right: $input-btn-padding-x + 0.875rem !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: "";
    &.form-control {
        color: $input-placeholder-color;
    }
    &:focus {
        color: $input-color;
    }
}

// FOrm Row

.form-row {
    margin-right: -12px;
    margin-left: -12px;
    > .col,
    > [class*="col-"] {
        padding-right: 12px;
        padding-left: 12px;
    }
}
